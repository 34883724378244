<template>
  <div class="page__wrapper">
    <ag-grid
      ref="nvnrGrid"
      style="width:100%;height:100%;"
      :column-defs="columnDefs"
      :row-data.sync="tableData"
      :grid-options="gridOptions"
      :framework-components="frameworkComponents"
    >
      <!-- 查询条件 -->
      <simple-form
        v-model="searchModel"
        :form-field="searchFormField"
      >
        <template slot="tableOperation">
          <el-button
            v-show="editable"
            type="primary"
            @click="handleAddClick"
          >
            {{ $t('operation.create') }}
          </el-button>
          <el-button @click="handleDataSearch">
            {{ $t('operation.search') }}
          </el-button>
        </template>
      </simple-form>
    </ag-grid>
  </div>
</template>

<script>
import Vue from 'vue'

const BASEURL = {
  list: '/jlr/tt/newCarNonRetailDetails/list',
  update: '/jlr/tt/newCarNonRetailDetails/update',
  save: '/jlr/tt/newCarNonRetailDetails/save',
  delete: '/jlr/tt/newCarNonRetailDetails/delete'
}

/** 自定义操作列 */
const Operation = Vue.extend({
  inject: ['handleDataSearch', 'handleDeleteClick', 'handleSaveClick'],
  template: `<div style="height:100%;text-align:center;">
      <el-tooltip effect="light" :content="$t('operation.save')">
        <el-button type="text" style="font-size:14px;" icon="el-icon-circle-check" @click="handleSaveClick(params)" />
      </el-tooltip>
      <el-tooltip effect="light" :content="$t('operation.cancel')">
        <el-button type="text" style="font-size:14px;" icon="el-icon-refresh-left" @click="handleDataSearch" />
      </el-tooltip>
      <el-tooltip effect="light" :content="$t('operation.delete')">
        <el-button type="text" style="font-size:14px;" icon="el-icon-delete" @click="handleDeleteClick(params.data)" />
      </el-tooltip>
    </div>`
})

export default {
  name: 'NewVehicleNotRetail',
  props: {
    editable: { type: Boolean, default: false },
    detailData: { type: Object }
  },
  data () {
    return {
      searchModel: { page: { pageSize: 10, pageNo: 1 } },
      gridOptions: {},
      frameworkComponents: {},
      isShowDetailDialog: false,
      tableData: []
    }
  },
  provide () {
    return {
      handleDataSearch: this.handleDataSearch,
      handleSaveClick: this.handleSaveClick,
      handleDeleteClick: this.handleDeleteClick
    }
  },
  computed: {
    searchFormField () {
      return [
        // {
        //   prop: 'vin',
        //   type: 'Input',
        //   labelWidth: '50px',
        //   col: { xs: 4, sm: 4, md: 4 },
        //   label: this.$t('dealer_finance.newVehicleNotRetail.vin'),
        //   component: { clearable: true }
        // },
        {
          slotName: 'tableOperation',
          labelWidth: '0',
          col: { xs: 24, sm: 24, md: 24 },
          style: { textAlign: 'right' }
        }
      ]
    },
    columnDefs () {
      return [
        {
          headerName: this.$t('dealer_finance.newVehicleNotRetail.tableTitle'),
          field: 'tableTitle',
          children: [
            {
              headerName: this.$t('dealer_finance.newVehicleNotRetail.vin'),
              field: 'vin',
              pinned: 'left',
              editable: params => !params.data.id && this.editable,
              cellEditorParams: {
                rules: { required: true, message: this.$t('validate.isRequired') }
              },
              valueFormatter: params => params.value ? params.value.substring(params.value.length - 9, params.value.length) : '',
              minWidth: 180
            },
            {
              headerName: this.$t('dealer_finance.newVehicleNotRetail.modelName'),
              field: 'model',
              valueFormatter: params => {
                return this.$getDictLabel({ type: 'tt_model', value: params.value })
              },
              minWidth: 180
            },
            {
              headerName: this.$t('dealer_finance.newVehicleNotRetail.beTransferred'),
              field: 'transferee',
              editable: this.editable,
              minWidth: 180
            },
            {
              headerName: this.$t('dealer_finance.newVehicleNotRetail.salesCategory'),
              field: 'salesCategory',
              editable: this.editable,
              type: 'Select',
              cellEditorParams: {
                componentProp: {
                  optionList: this.$getDictList('tt_sales_category32').map(item => {
                    return { value: item.value, label: item.label }
                  })
                }
              },
              valueFormatter: params => {
                return this.$getDictLabel({ type: 'tt_sales_category32', value: params.value })
              },
              minWidth: 180
            },
            {
              headerName: this.$t('dealer_finance.newVehicleNotRetail.incomeWithoutTax'),
              field: 'income',
              editable: this.editable,
              valueParser: params => Number(this.$utils.decimalFormat(params.newValue, 2)),
              valueFormatter: params => this.$utils.numberFormat(params.value, { thousand: true, toFixed: 2 }),
              cellEditorParams: {
                rules: { type: 'number', message: this.$t('validate.notNumber') }
              },
              minWidth: 180
            },
            {
              headerName: this.$t('dealer_finance.newVehicleNotRetail.costWithoutTax'),
              field: 'cost',
              editable: this.editable,
              valueParser: params => Number(this.$utils.decimalFormat(params.newValue, 2)),
              valueFormatter: params => this.$utils.numberFormat(params.value, { thousand: true, toFixed: 2 }),
              cellEditorParams: {
                rules: { type: 'number', message: this.$t('validate.notNumber') }
              },
              minWidth: 180
            },
            {
              headerName: this.$t('dealer_finance.newVehicleNotRetail.grossProfit1'),
              field: 'maoriOne',
              valueFormatter: params => this.$utils.numberFormat(params.value, { thousand: true, toFixed: 2 }),
              minWidth: 180
            }
          ]
        },
        {
          headerName: this.$t('dealer_finance.newVehicleNotRetail.formulaArea'),
          field: 'formulaArea',
          children: [
            {
              headerName: this.$t('dealer_finance.newVehicleNotRetail.jbOrLh'),
              field: 'brand',
              valueFormatter: params => {
                return this.$getDictLabel({ type: 'tt_brand', value: params.value })
              },
              minWidth: 180
            },
            {
              headerName: this.$t('dealer_finance.newVehicleNotRetail.domesticOrImport'),
              field: 'origin',
              valueFormatter: params => {
                return this.$getDictLabel({ type: 'tt_origin', value: params.value })
              },
              minWidth: 180
            }
          ]
        },
        {
          headerName: this.$t('field.operation'),
          width: 100,
          pinned: 'right',
          hide: !this.editable,
          suppressSizeToFit: true,
          cellRenderer: 'Operation'
        }
      ]
    }
  },
  watch: {
    editable (val) {
      this.$nextTick(() => {
        if (this.$refs.nvrGrid) this.$refs.nvrGrid.redrawGrid(this.columnDefs)
      })
    }
  },
  beforeMount () {
    this.frameworkComponents = { Operation: Operation }
  },
  methods: {
    handleDataSearch () {
      const params = Object.assign({}, this.$utils.parseQueryCondition(this.searchModel, this.searchFormField))
      params.filter.push({ left: 'head.id', operate: '=', right: this.detailData.id })
      params.page = { orderBy: 'sort' }
      const loadingFlag = this.$loading({ fullscreen: false, target: this.$el, text: this.$t('tip.systemLoading') })
      this.$axios
        .post(BASEURL.list, params)
        .then(resp => {
          const respData = resp.data
          this.tableData = respData.list
        })
        .finally(() => {
          loadingFlag.close()
        })
    },
    handleAddClick () {
      this.tableData.push({})
      this.$nextTick(() => {
        this.$refs.nvnrGrid.focusOnCell(this.tableData.length - 1, 'vin')
      })
    },
    handleSaveClick (row) {
      this.$refs.nvnrGrid.validate(row, status => {
        if (status) {
          const loadingFlag = this.$loading({ target: this.$el.querySelector('.el-dialog') })
          let submitData = this.$_.cloneDeep(row.data)
          submitData.head = { id: this.detailData.id }
          this.$axios
            .post(row.data.id ? BASEURL.update : BASEURL.save, submitData)
            .then(resp => {
              this.currentShowFlag = false
              this.$message({ type: 'success', message: this.$t('tip.saveSuccess') })
              this.handleDataSearch()
            })
            .finally(() => {
              loadingFlag.close()
            })
        }
      })
    },
    handleDeleteClick (row) {
      if (!row.id) return this.handleDataSearch()
      this.$msgbox({
        title: this.$t('tip.warning'),
        message: this.$t('tip.confirmDelete'),
        type: 'warning',
        showCancelButton: true,
        closeOnClickModal: false,
        closeOnPressEscape: false,
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true
            this.$axios
              .post(BASEURL.delete, { id: row.id })
              .then(resp => {
                this.$message({ type: 'success', message: this.$t('tip.deleteSuccess') })
                done()
              })
              .finally(() => {
                instance.confirmButtonLoading = false
              })
          } else done()
        }
      })
        .then(() => {
          this.handleDataSearch()
        })
    }
  }
}
</script>
<style lang="less" scoped>
  .page__wrapper {
    width: 100%;
    height: 100%;
  }
</style>
